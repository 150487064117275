<script>
import ListMain from '../../../../components/list/Main.vue'
import ListHeader from '../../../../components/list/Header.vue'
import ListShowButton from '../../../../components/list/ShowButton.vue'
import EntryStatus from './labels/StatusLabel.vue'
import EntryStatusCircle from './labels/StatusCircle.vue'
import ValidationMessage from '../../../../components/form/ValidationMessage.vue'

import DataService from '../../../../services/dataService'
import * as restfulService from '../../../../services/restfulService'
import * as notifyService from '../../../../services/notifyService'

import helpers from '@mixins/helpers'
import bus from '@utils/bus'
import {mapGetters} from 'vuex'

import Swal from 'sweetalert2'
import {clone} from 'lodash'
import moment from 'moment'
import {required} from 'vuelidate/lib/validators'

const defaultFilters = {
  type: 'expense',
  client_id: '',
  status: 'created',
  show_opened_entries: false,
  dates: [helpers.methods.dateFirstDayOfMonth(true), helpers.methods.dateLastDayOfMonth(true)],
  classification_ids: [],
}

export default {
  components: {ListMain, ListHeader, ListShowButton, EntryStatus, EntryStatusCircle, ValidationMessage},
  mixins: [helpers],
  data() {
    return {
      isLoadingData: true,
      isSubmitted: false,
      filters: {...clone(defaultFilters)},
      searchData: {
        type: defaultFilters.type,
        status: defaultFilters.status,
        start_date: this.dateFormat(defaultFilters.dates[0], 'en'),
        end_date: this.dateFormat(defaultFilters.dates[1], 'en')
      },
      selectedList: [],
      showModalSettledSelected: false,
      paymentMethodId: '',
      showModalSettledPortion: false,
      showPortionPayDateDatePicker: false,
      showModalPdf: false,
      pdfBase64: '',
      portion: {
        id: '',
        payment_method_id: '',
        pay_date: '',
        amount_paid: '',
        description: ''
      },
      moneyMask: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
        masked: false
      },
      dependencies: {
        clients: [],
        status: [],
        payment_methods: [],
        classifications: [],
      },
      importFile: {
        modalVisible: false,
        reset: false,
        type: '',
        size: '',
        filename: '',
        base64: ''
      }
    }
  },
  computed: {
    ...mapGetters(['listStore']),
    portionPayDateFormatted: {
      get() {
        return this.portion.pay_date ? this.dateFormat(this.portion.pay_date, 'br') : ''
      },
      set(value) {
        console.log(value)
      }
    },
    totalAmountCreated: function () {
      return this.currencyEnToBr(this.listStore.listData.filter((item) => item.status === 'created').reduce((a, b) => a + b.amount, 0))
    },
    totalAmountSettled: function () {
      return this.currencyEnToBr(this.listStore.listData.filter((item) => item.status === 'settled').reduce((a, b) => a + b.amount_paid, 0))
    },
    isAllChecked() {
      return this.selectedList.length === this.listStore.listData.length
    }
  },
  validations: {
    portion: {
      payment_method_id: {required},
      pay_date: {required},
      amount_paid: {required}
    }
  },
  mounted() {
    this.getDependencies()
    bus.$emit('list-init', {
      domain: 'entry_search',
      searchRoute: false,
      processResponse: this.processListResponse,
      data: this.searchData
    }, () => {
      this.isLoadingData = false
    })
  },
  methods: {
    processListResponse(list) {
      list.forEach((item) => {
        item.received_late = item.pay_date > item.due_date && item.status === 'settled'
        item.overdue = moment(item.due_date, 'YYYY-MM-DD') < moment() && item.status !== 'settled'
        if (item.amount_paid && item.amount_paid < item.amount) {
          item.outstanding = true
        }
        item.amount_br = this.currencyEnToBr(item.amount)
        if (item.amount_paid) {
          item.amount_paid_br = this.currencyEnToBr(item.amount)
        }
        item.checked = false
      })
      return list
    },
    getDependencies() {
      DataService.get([{domain: 'client'}, {domain: 'status'}, {domain: 'payment_method'}, {domain: 'classification_expense'}]).then((result) => {
        this.dependencies = result
      })
    },
    onFilter() {
      return new Promise(resolve => {
        const searchData = {}
        searchData.type = this.filters.type
        if (this.filters.client_id > 0) {
          searchData.client_id = this.filters.client_id
        }
        if (this.filters.classification_ids.length > 0) {
          searchData.classification_ids = this.filters.classification_ids
        }
        searchData.status = this.filters.status ? this.filters.status : null
        if (this.filters.dates && this.filters.dates.length > 0) {
          let startDate = this.dateFormat(this.filters.dates[0], 'en')
          let endDate = this.dateFormat(this.filters.dates[1], 'en')
          if (startDate.length > 0 && endDate.length > 0) {
            searchData.start_date = startDate
            searchData.end_date = endDate
          }
        }
        searchData.show_opened_entries = this.filters.show_opened_entries
        if (searchData.status === 'settled') {
          searchData.show_opened_entries = false
        }
        bus.$emit('list-filter', searchData, () => {
          this.resetSelectedList()
          resolve()
        })
      })
    },
    onFilterClean() {
      this.filters = {...clone(defaultFilters)}
      bus.$emit('list-filter-clean', this.filters, this.searchData)
    },
    onDestroy(item) {
      bus.$emit('list-destroy-item', item)
    },
    checkItem(entry) {
      if (this.selectedList.some(item => item === entry.id)) {
        this.selectedList = this.selectedList.filter(item => item !== entry.id)
        entry.checked = false
      } else {
        this.selectedList.push(entry.id)
        entry.checked = true
      }
    },
    checkAll() {
      if (this.isAllChecked) {
        this.resetSelectedList()
      } else {
        this.selectedList = [...this.listStore.listData.map(item => item.id)]
        this.listStore.listData.forEach(entry => {
          entry.checked = true
        })
      }
    },
    selectedAction(command) {
      if (command === 'Quitar') {
        this.handleModalSettledSelected()
      }
    },
    resetSelectedList() {
      this.selectedList.splice(0)
      this.listStore.listData.forEach(entry => {
        entry.checked = false
      })
    },
    handleModalSettledSelected() {
      this.showModalSettledSelected = !this.showModalSettledSelected
    },
    handleModalSettledPortion() {
      this.showModalSettledPortion = !this.showModalSettledPortion
    },
    async settledSelected() {
      Swal.fire({
        title: 'Deseja realmente quitar os lançamentos selecionados?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, continuar!',
        cancelButtonText: 'Cancelar'
      })
          .then((result) => {
            if (result.value) {
              bus.$emit('show-loader')
              const data = {entries: this.selectedList, payment_method_id: this.paymentMethodId}
              restfulService.post('entry_portion', 'finish-multiple', null, data)
                  .then(() => {
                    notifyService.success({message: 'Lançamentos quitados com sucesso.'})
                    this.onFilter().then(() => {
                      setTimeout(() => {
                        this.handleModalSettledSelected()
                      }, 300)
                    })
                  })
                  .catch(e => {
                    console.log(e)
                    bus.$emit('hide-loader')
                  })
            }
          })
    },
    openSettlePortion(portion) {
      let portionData = clone(portion)
      portionData.amount_paid = this.currencyEnToBr(portionData.amount)
      portionData.pay_date = this.dateNow('en')
      this.portion = {...portionData}
      this.handleModalSettledPortion()
    },
    settledPortion() {
      this.isSubmitted = true
      const data = clone(this.portion)
      data.amount_paid = this.currencyBrToEn(data.amount_paid)
      if (data.id) {
        restfulService.put('entry_portion', 'finish', data.id, data)
            .then(() => {
              this.isSubmitted = false
              this.onFilter().then(() => {
                setTimeout(() => {
                  this.handleModalSettledPortion()
                }, 300)
              })
            })
            .catch(e => {
              this.isSubmitted = false
              console.log(e)
            })
      } else {
        restfulService.post('entry_portion', 'finish-temp', null, data)
            .then(() => {
              this.isSubmitted = false
              this.onFilter().then(() => {
                setTimeout(() => {
                  this.handleModalSettledPortion()
                }, 300)
              })
            })
            .catch(e => {
              this.isSubmitted = false
              console.log(e)
            })
      }
    },
    generateBankSlip(item) {
      bus.$emit('show-loader')
      const data = {entry_id: item.entry_id, due_date: item.due_date, entry_portion_id: item.id}
      restfulService.post('entry_portion', 'bank-slip', null, data)
          .then(response => {
            notifyService.success({message: 'Boleto gerado com sucesso.'})
            this.showBankSlipPdf(response.base64)
            this.onFilter()
          })
          .catch(e => {
            console.log(e)
            bus.$emit('hide-loader')
          })
    },
    showBankSlip(item) {
      bus.$emit('show-loader')
      restfulService.get('entry_portion', 'bank-slip', item.id)
          .then(response => {
            notifyService.success({message: 'Boleto gerado com sucesso.'})
            this.showBankSlipPdf(response.base64)
            bus.$emit('hide-loader')
          })
          .catch(e => {
            console.log(e)
            bus.$emit('hide-loader')
          })
    },
    showBankSlipPdf(base64) {
      this.pdfBase64 = base64
      this.showModalPdf = true
    },
    selectFile(event) {
      const file = event.target.files[0]
      this.importFile.filename = file.name
      this.importFile.size = file.size
      this.importFile.type = file.type
      if (file.type !== 'text/csv') {
        notifyService.warning({message: 'Formato de arquivo inválido, somente arquivos CSV são suportados'})
        return
      }
      let reader = new window.FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        this.importFile.base64 = reader.result
      }
    },
    openModalImport() {
      this.resetImport()
      this.importFile.modalVisible = true
    },
    resetImport() {
      this.importFile.reset = true
      this.importFile.filename = ''
      this.importFile.base64 = ''
      this.importFile.size = ''
      this.importFile.type = ''
      setTimeout(() => {
        this.importFile.reset = false
      }, 50)
    },
    importCSV() {
      bus.$emit('show-loader')
      this.isSubmitted = true
      const data = clone(this.importFile)
      restfulService.post('import-expenses', null, null, data)
          .then(() => {
            notifyService.warning({message: 'Despesas importados com sucesso'})
            this.isSubmitted = false
            this.onFilter()
          })
          .catch(e => {
            this.resetFile = true
            bus.$emit('hide-loader')
            setTimeout(() => {
              this.resetFile = false
            }, 200)
            this.isSubmitted = false
            console.log(e)
          })
    }
  }
}
</script>

<template>
  <div>

    <ListHeader title="Contas a Pagar"
                route-name="entry.expense.store"
                store-title="Criar Lançamento">
      <el-button type="warning" @click="openModalImport">
        <i class="fas fa-file-code"></i>
        <span>Importar Despesas</span>
      </el-button>
      <router-link :to="{ name: 'entry.expense.store', params: {simpleEntry: true} }" class="el-button">
        <i class="fas fa-plus-circle"></i>
        <span>Criar Lançamento Simples</span>
      </router-link>
    </ListHeader>

    <ListMain>

      <div slot="filters">

        <el-card class="el-card__search_list box-card">
          <div slot="header">
            <span>Filtrar Listagem</span>
            <div>
              <el-button type="primary" @click="onFilter" size="small">
                <i class="fas fa-filter"></i>
                <span>Pesquisar</span>
              </el-button>
              <el-button type="default" @click="onFilterClean" size="small">
                <i class="fas fa-broom"></i>
                <span>Limpar</span>
              </el-button>
            </div>
          </div>
          <el-form :inline="true" :model="filters" class="demo-form-inline">
            <el-form-item label="Cliente">
              <el-select v-model="filters.client_id"
                         filterable
                         clearable
                         placeholder="Filtrar por cliente"
                         empty-text="Nenhum registro encontrado">
                <el-option v-for="item in dependencies.clients"
                           :key="item.id"
                           :label="item.name"
                           :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Classificação">
              <el-select v-model="filters.classification_ids"
                         filterable
                         clearable
                         multiple
                         placeholder="Filtrar por Classificação"
                         empty-text="Nenhum registro encontrado"
                         class="el-select-full">
                <el-option
                    v-for="item in dependencies.classifications"
                    :key="item.id"
                    :label="item.description"
                    :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Vencimento">
              <el-date-picker
                  v-model="filters.dates"
                  type="daterange"
                  format="dd/MM/yyyy"
                  placeholder="Filtrar por vencimento">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="Status">
              <el-select v-model="filters.status"
                         filterable
                         clearable
                         placeholder="Filtrar por Status"
                         empty-text="Nenhum registro encontrado">
                <el-option v-for="item in dependencies.status"
                           :key="item.id"
                           :label="item.name"
                           :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Mostrar Lançamentos Antigos" v-if="filters.status !== 'settled'">
              <el-switch v-model="filters.show_opened_entries"
                         :active-value="true"
                         :inactive-value="false"
                         active-text="SIM"
                         inactive-text="NÃO">
              </el-switch>
            </el-form-item>
          </el-form>
        </el-card>

      </div>

      <div slot="headerTitle">
        <span>Listagem</span>
        <div class="entries-selected d-inline-block">
          <el-dropdown :disabled="selectedList.length === 0"
                       @command="selectedAction"
                       v-if="selectedList.length > 0">
            <el-button :type="selectedList.length === 0 ? 'default' : 'primary'" size="small">
              <span>Com selecionados:</span>
              <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="Quitar">Quitar</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <div slot="headerList">
        <div class="legends">
          <div class="legend-entry">
            <span>Legendas:</span>
          </div>
          <div class="legend-entry">
            <span class="label label-default"></span>
            <span>Aguardando pagamento</span>
          </div>
          <div class="legend-entry">
            <span class="label label-danger"></span>
            <span>Em Atraso</span>
          </div>
          <div class="legend-entry">
            <span class="label label-success"></span>
            <span>Quitada</span>
          </div>
          <div class="legend-entry">
            <span class="label label-warning"></span>
            <span>Quitada com Atraso</span>
          </div>
        </div>
      </div>

      <div slot="list" v-if="!isLoadingData">
        <table class="custom-table-list">
          <thead>
          <tr>
            <th></th>
            <th>
              <el-checkbox @change="checkAll" :value="isAllChecked"/>
            </th>
            <th>Vencimento</th>
            <th>Cliente</th>
            <th>Classificação</th>
            <th>Descrição</th>
            <th>Parcela</th>
            <th>Status</th>
            <th>Valor</th>
            <th class="btn-actions">Ações</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item in listStore.listData"
              :class="{'row-danger': item.overdue}">
            <td class="list-table-nowrap">
              <entry-status-circle :item="item"/>
            </td>
            <th class="list-table-nowrap">
              <el-checkbox @change="checkItem(item)" v-model="item.checked" :disabled="!item.id"/>
            </th>
            <td>{{ item.due_date | dateEnToBr }}</td>
            <td>
              {{ item.entry.client.name }}
              <span v-if="!!item.entry.client.cpf_cnpj" class="ml-2">( {{ item.entry.client.cpf_cnpj }} )</span>
            </td>
            <td>{{ item.entry.classification.description }}</td>
            <td>{{ item.description }}</td>
            <td>{{ item.portion }} / {{ item.entry.portions_number }}</td>
            <td>
              <div>
                <entry-status :status="item.status"/>
              </div>
              <div v-if="item.status === 'settled'" class="settled-at">
                Em: {{ item.pay_date | dateEnToBr }}
              </div>
            </td>
            <td align="right">
              R$ {{ currencyEnToBr(item.status === 'settled' ? item.amount_paid : item.amount) }}
            </td>
            <td class="list-table-nowrap">
              <div class="btn-actions">
                <el-button type="warning"
                           size="small"
                           v-if="false && !item.transaction_id"
                           @click="generateBankSlip(item)"
                           title="Gerar Boleto">
                  <i class="fas fa-barcode"></i>
                </el-button>
                <el-button type="info"
                           size="small"
                           v-if="item.transaction_id"
                           @click="showBankSlip(item)"
                           title="Exibir Boleto">
                  <i class="fas fa-barcode"></i>
                </el-button>
                <el-button type="success"
                           size="small"
                           v-if="item.status === 'created'"
                           @click="openSettlePortion(item)"
                           title="Quitar">
                  <i class="far fa-money-bill-alt"></i>
                </el-button>
                <ListShowButton route-name="entry.expense.show" :item="item.entry"/>
              </div>
            </td>
          </tr>
          </tbody>
          <tfoot>
          <tr>
            <th colspan="8" align="right">Total Aberto:</th>
            <th align="right">R$ {{ totalAmountCreated }}</th>
            <th></th>
          </tr>
          <tr>
            <th colspan="8" align="right">Total Pago:</th>
            <th align="right">R$ {{ totalAmountSettled }}</th>
            <th></th>
          </tr>
          </tfoot>
        </table>

      </div>

    </ListMain>

    <el-dialog title="Quitar Vários"
               :visible.sync="showModalSettledSelected"
               width="50%"
               top="1vh">
      <h3>Forma de Pagamento</h3>
      <el-select v-model="paymentMethodId"
                 filterable
                 clearable
                 class="el-select-full"
                 placeholder="Selecione um forma de pagamento"
                 empty-text="Nenhum registro encontrado">
        <el-option v-for="item in dependencies.payment_methods"
                   :key="item.id"
                   :label="item.description"
                   :value="item.id">
        </el-option>
      </el-select>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleModalSettledSelected()">Cancelar</el-button>
        <el-button type="primary"
                   :disabled="selectedList.length === 0 || !paymentMethodId"
                   @click="settledSelected()">
          Quitar
        </el-button>
      </div>
    </el-dialog>

    <el-dialog title="Quitar Lançamento"
               :visible.sync="showModalSettledPortion"
               width="70%"
               top="1vh"
               custom-class="finish-order-dialog">
      <el-form v-model="portion" label-position="top">
        <el-form-item label="Forma de Pagamento"
                      class="is-required"
                      :class="{ 'el-form-item--error': $v.portion.payment_method_id.$error }">
          <el-select v-model="portion.payment_method_id"
                     @input="$v.portion.payment_method_id.$touch()"
                     class="el-select-full"
                     placeholder="Selecione um forma de pagamento"
                     empty-text="Nenhum registro encontrado">
            <el-option v-for="item in dependencies.payment_methods"
                       :key="item.id"
                       :label="item.description"
                       :value="item.id">
            </el-option>
          </el-select>
          <validation-message v-if="$v.portion.payment_method_id.$error"/>
        </el-form-item>
        <el-row :gutter="10">
          <el-col :span="12">
            <el-form-item label="Data de Pagamento"
                          class="is-required"
                          :class="{ 'el-form-item--error': $v.portion.pay_date.$error }">
              <el-date-picker
                  v-model="portion.pay_date"
                  @input="$v.portion.pay_date.$touch()"
                  type="date"
                  format="dd/MM/yyyy"
                  value-format="yyyy-MM-dd"
                  placeholder="Data de Pagamento">
              </el-date-picker>
              <validation-message v-if="$v.portion.pay_date.$error"/>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Valor Pago"
                          class="is-required"
                          :class="{ 'el-form-item--error': $v.portion.amount_paid.$error }">
              <el-input v-model.lazy="portion.amount_paid"
                        v-money="moneyMask"
                        @input="$v.portion.amount_paid.$touch()"/>
              <validation-message v-if="$v.portion.amount_paid.$error"/>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="Descrição/Observações">
          <el-input type="textarea"
                    :rows="4"
                    v-model="portion.description"/>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleModalSettledPortion()">Cancelar</el-button>
        <el-button type="primary"
                   :disabled="isSubmitted || $v.portion.$invalid"
                   @click="settledPortion()">
          Quitar
        </el-button>
      </div>
    </el-dialog>

    <el-dialog title="Visualizando PDF"
               :visible.sync="showModalPdf"
               width="80%">
      <div class="modal-pdf">
        <iframe :src="pdfBase64"></iframe>
      </div>
      <div slot="footer" class="dialog-footer">
        <div/>
        <el-button @click="showModalPdf = false">
          Fechar
        </el-button>
      </div>
    </el-dialog>

    <el-dialog
        title="Importar despesas"
        v-if="importFile.modalVisible"
        :visible.sync="importFile.modalVisible">
      <el-form :model="importFile" ref="form" label-position="top">
        <el-form-item label="Selecione XML da Nota de Compra"
                      class="is-required">
          <input v-if="!importFile.reset"
                 type="file"
                 accept="text/csv"
                 @change="selectFile($event)">
          <el-button @click="resetImport"
                     size="mini"
                     class="mt-2"
                     v-if="!!importFile.base64">
            <i class="fas fa-times"></i>
            <span>Remover Arquivo</span>
          </el-button>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="importFile.modalVisible = false">Fechar</el-button>
        <el-button
            type="primary"
            @click="importCSV"
            :disabled="!importFile.base64">Importar
        </el-button>
      </div>

    </el-dialog>
  </div>
</template>
